import React from 'react'
import './home-page.css'
import { ImageCarousel, ImageCarouselHorizontal } from '../../components'
import { useNavigate } from 'react-router-dom'

const HomePage = (): React.JSX.Element => {
  const navigate = useNavigate()

  return (
    <main className='home-page-container'>
      <div className="content">
        <h2>Nossos Produtos</h2>
      </div>
      <div className='home-page-produtos'>
        <div className='home-page-gas'>
          <h3>Mercado de Petróleo, Gás e Energia</h3>
          <p>
            Tecnologia e inovação para o setor energético: um ecossistema completo com gestão inteligente de gás, soluções de abastecimento, serviços financeiros, internet das coisas e proteção contra fraudes integrado à plataforma i-fraud.
          </p>
          <div style={{ maxWidth: '200px', margin: '0 auto', paddingBottom: '30px' }}>
            <ImageCarousel images={['/carrinho.jpeg', '/i-fraud.jpeg', '/menu.jpeg', '/mercado.jpeg', '/score.jpeg', '/timer.jpeg']} />
          </div>
          <button className='home-page-button'>Explorar funcionalidades</button>
        </div>
        <div className='home-page-financeiro'>
          <h3>Mercado Financeiro</h3>
          <p>
            A plataforma i-fraud estabelece um ecossistema dinâmico para prevenir fraudes e recuperar valores de maneira rápida e eficiente. Esse processo se baseia em três pilares: velocidade na atuação, orquestração entre instituições e inteligência baseada em dados.
          </p>
          <div style={{ maxWidth: '450px', margin: '0 auto', paddingBottom: '30px' }}>
            <ImageCarouselHorizontal images={['/Slide1.png', '/Slide2.png', '/Slide3.png', '/Slide4.png', '/Slide5.png', '/Slide6.png']} />
          </div>
          <button
            className='home-page-button'
            onClick={() => navigate('/i-fraud')}
          >
            Explorar funcionalidades
          </button>
        </div>
      </div>
    </main>
  )
}
export default HomePage