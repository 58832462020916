import React from 'react'
import Slider from 'react-slick'
import './image-carousel.css'

interface ImageCarouselProps {
  images: string[]
}

const ImageCarouselHorizontal: React.FC<ImageCarouselProps> = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000
  }

  return (
    <Slider {...settings}>
      {images.map((img, index) => (
        <div key={index} className='slider-div'>
          <img
            src={img}
            alt={`Slide ${index}`}
            style={{
              width: '95%',
              height: 'auto',
              borderRadius: 8,
              border: '1px solid white'
            }}
          />
        </div>
      ))}
    </Slider>
  )
}

export default ImageCarouselHorizontal;
