import React, { ReactNode, useState } from 'react'
import InputMask from 'react-input-mask'
import './i-fraud.css'

interface CardProps {
  children: ReactNode
  className?: string
}

const Card: React.FC<CardProps> = ({ children, className = '' }) => {
  return <div className={`card ${className}`}>{children}</div>
}

const CardContent: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className = '',
}) => {
  return <div className={`card-content ${className}`}>{children}</div>
}

const Ifraud = (): React.JSX.Element => {
  const [formData, setFormData] = useState({
    nome: '',
    empresa: '',
    telefone: '',
    cnpj: '',
    email: '',
  })

  const destinatarios = [
    'sac@smartgaas.com',
    'sac@i-fraud.com',
    'coo@i-fraud.com',
    'ceo@i-fraud.com'
  ]

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const validateTelefone = (telefone: string) => {
    const apenasNumeros = telefone.replace(/\D/g, '')
    return apenasNumeros.length >= 10
  }

  const validateCNPJ = (cnpj: string) => {
    const apenasNumeros = cnpj.replace(/\D/g, '')
    return apenasNumeros.length === 14
  }


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const { nome, empresa, telefone, cnpj, email } = formData

    if (!validateTelefone(telefone)) {
      alert('Telefone inválido. Insira ao menos 10 dígitos numéricos.')
      return
    }

    if (cnpj && !validateCNPJ(cnpj)) {
      alert('CNPJ inválido. Deve conter exatamente 14 dígitos numéricos.')
      return
    }

    if (!validateEmail(email)) {
      alert('E-mail inválido.')
      return
    }


    const subject = `Novo contato de ${nome}`
    const body = `
      Nome: ${nome}
      Empresa: ${empresa}
      Telefone: ${telefone}
      CNPJ: ${cnpj}
      E-mail: ${email}
    `

    const mailtoLink = `mailto:${destinatarios.join(',')}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`

    window.location.href = mailtoLink
  }

  return (
    <main className='ifraud-container'>
      <section className='video-section'>
        <video
          autoPlay
          muted
          loop
          playsInline
          className='background-video'
        >
          <source src='/networking_animation.mp4' type='video/mp4' />
          Seu navegador não suporta a tag de vídeo.
        </video>
      </section>

      <section className='section-container'>
        <div className='ifraud-content'>
          <p className='ifraud-subtitle'>
            Ecossistema completo contra fraudes financeiras com IA avançada,
            comunicação ágil e recuperação rápida e segura de valores.
          </p>
        </div>
        <div className='card-grid'>
          <Card className='hoverable'>
            <CardContent>
              <h3>🧠 IA e Rede Neural</h3>
              <p>
                Análise avançada em tempo real, detectando padrões suspeitos e prevenindo
                fraudes com inteligência artificial e redes neurais avançadas. Plataforma
                em conformidade com a Resolução 6 e 589 do Banco Central e integrada com a
                Interoperabilidade entre os demais provedores.
              </p>
            </CardContent>
          </Card>

          <Card className='hoverable'>
            <CardContent>
              <h3>⚙ Orquestração Automatizada</h3>
              <p>
                A plataforma notifica automaticamente todos os atores envolvidos em uma
                recuperação 1:1 ou 1:N (quando ocorre a triangulação de valores entre
                Instituições Financeiras ou Instituições de Pagamentos) em arranjos como
                PIX, TED, Boletos ou Cartões.
              </p>
            </CardContent>
          </Card>

          <Card className='hoverable'>
            <CardContent>
              <h3>💬 Comunicação Segura</h3>
              <p>
                Chat criptografado de comunicação entre todos os usuários das Instituições
                Financeiras e Instituições de Pagamentos.
              </p>
            </CardContent>
          </Card>
        </div>

        <div className='why-choose-us'>
          <h2>Porque escolher o ecossistema i-fraud?</h2>
          <p>
            Porque atuamos como a engrenagem e o parceiro estratégico que impulsionam
            produtividade, eficiência e eficácia nas suas operações.
          </p>

          <div className='grid-cards-2'>
            <Card className='hoverable'>
              <CardContent>
                <h3>🛡 Detecção de Fraudes e Golpes em Tempo Real</h3>
                <p>
                  Identifique transações fraudulentas antes que causem impactos,
                  sem prejudicar a experiência do usuário.
                </p>
                <p>APIs:</p>
                <ul>
                  <li>Onboarding</li>
                  <li>Login</li>
                  <li>Transacional</li>
                  <li>Bloqueio Cautelar</li>
                  <li>Recuperação de Valores</li>
                  <li>Revalidação Semestral de contas</li>
                  <li>Inclusão e Consultas na Resolução 6 e Interoperabilidade</li>
                </ul>
              </CardContent>
            </Card>

            <Card className='hoverable'>
              <CardContent>
                <h3>🔎 Baixos Índices de Falsos Positivos</h3>
                <p>
                  Minimize a interrupção das operações legítimas, com uma precisão
                  excepcional na detecção de fraudes e golpes. Crie novas categorias
                  de fraudes e golpes adequadas as ocorrências de sua operação.
                </p>
                <ul className='check-list'>
                  <li>Deixe nossa IA trabalhar por você</li>
                  <li>Visualize a Rede Neural de sua instituição em segundos</li>
                  <li>Analise o Benckmark do nível de maturidade das Operações</li>
                  <li>Agilidade para bloquear, recuperar e proteger seus clientes</li>
                  <li>Sem tempo para API? Faça tudo através do Portal i-fraud</li>
                </ul>
              </CardContent>
            </Card>

            <Card className='hoverable'>
              <CardContent>
                <h3>🔐 Escalabilidade</h3>
                <p>
                  Adeque-se facilmente a diferentes volumes de transações, sem comprometer
                  a segurança.
                </p>
                <ul className='check-list'>
                  <li>Autenticação e MFA (baseado @domínio de sua empresa)</li>
                  <li>Autorização com Controle de Acesso Baseado em (RBAC)</li>
                  <li>Registro e Auditoria (logs de acesso e auditoria de atividades)</li>
                  <li>Controle de Acesso por Localização IP</li>
                  <li>Gestão de Usuários com controle pelo cliente</li>
                  <li>Acesso Externo e APIs (KeyCloak – OAuth 2.0 e MTLS)</li>
                  <li>Ambiente para Homologação e Prova de Conceito</li>
                  <li>Hospedagem em Datacenters no Brasil</li>
                </ul>
              </CardContent>
            </Card>

            <Card className='hoverable'>
              <CardContent>
                <h3>📊 Relatórios detalhados para prevenção futura</h3>
                <p>
                  Receba insights claros e práticos para evitar fraudes e golpes semelhantes
                  no futuro e projeta seu maior ativo: seus clientes.
                </p>
                <p>Identifique:</p>
                <ul className='report-list'>
                  <li>Vítimas</li>
                  <li>Vítimas Suspeita</li>
                  <li>Suspeito</li>
                  <li>Falsidade Ideológica</li>
                  <li>Contas Laranjas</li>
                  <li>Lavagem de Dinheiro</li>
                </ul>
                <p>e retorne contextação para Ocorrências sem Evidências.</p>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className='form-container'>
          <div className='form-box'>
            <h2>Nossos especialistas estão prontos para ajudar a sua empresa</h2>
            <form onSubmit={handleSubmit}>
              <input
                type='text'
                name='nome'
                placeholder='Nome *'
                value={formData.nome}
                onChange={handleChange}
                required
              />
              <input
                type='text'
                name='empresa'
                placeholder='Empresa *'
                value={formData.empresa}
                onChange={handleChange}
                required
              />
              <InputMask
                mask='(99) 99999-9999'
                maskChar={null}
                type='text'
                name='telefone'
                placeholder='Telefone *'
                value={formData.telefone}
                onChange={handleChange}
                required
              >
                {(inputProps) => <input {...inputProps} />}
              </InputMask>
              <InputMask
                mask='99.999.999/9999-99'
                maskChar={null}
                type='text'
                name='cnpj'
                placeholder='CNPJ'
                value={formData.cnpj}
                onChange={handleChange}
              >
                {(inputProps) => <input {...inputProps} />}
              </InputMask>
              <input
                type='email'
                name='email'
                placeholder='E-mail corporativo *'
                value={formData.email}
                onChange={handleChange}
                required
              />

              <div className='checkbox-container'>
                <input type='checkbox' required />
                <span>
                  Ao clicar em "Receber contato dos especialistas", você está ciente
                  e concorda em compartilhar seus dados com a SmartGaaS e em receber
                  nossas comunicações. Confira mais informações em nossos Termos de
                  Uso e Política de Privacidade.
                </span>
              </div>

              <button type='submit'>Receber contato dos especialistas →</button>
            </form>
          </div>
        </div>
      </section>
    </main>
  )
}
export default Ifraud