import React, { useEffect, useState } from 'react'
import './cookie-banner.css'

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted')
    if (!cookiesAccepted) {
      setShowBanner(true)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true')
    setShowBanner(false)
  }

  if (!showBanner) return null

  return (
    <div className='cookie-banner'>
      <p>
        Usamos cookies para melhorar sua experiência de navegação, personalizar
        conteúdos e analisar o tráfego do site. Ao continuar navegando, você
        concorda com o uso de cookies.
      </p>
      <button onClick={handleAccept}>Aceitar</button>
    </div>
  )
}

export default CookieBanner
