import React from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { HeaderMenu, Footer, CookieBanner } from './components'
import { Contato, HomePage, Ifraud } from './pages'

function App(): JSX.Element {
  return (
    <div className="App">
      <HeaderMenu />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/contato' element={<Contato />} />
        <Route path='/i-fraud' element={<Ifraud />} />
      </Routes>
      <Footer />
      <CookieBanner />
    </div>
  )
}

export default App