import React from 'react'
import { FaInstagram, FaLinkedin, FaWhatsapp, FaEnvelope } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import './footer.css'

const Footer = (): JSX.Element => {
  return (
    <footer className='footer'>
      <div className='footer-div'>
        <div className='footer-left'>
          <div className='social-icons'>
            <a href="https://wa.me/5511978444334?text=Ol%C3%A1,%20estou%20entrando%20em%20contato%20pelo%20site" target="_blank" rel="noopener noreferrer" title="WhatsApp">
              <FaWhatsapp />
            </a>
            <a href="http://www.instagram.com/smartgaas" target="_blank" rel="noopener noreferrer" title="Instagram">
              <FaInstagram />
            </a>
            <a href="https://br.linkedin.com/company/smartgaas" target="_blank" rel="noopener noreferrer" title="LinkedIn">
              <FaLinkedin />
            </a>
            <a href="https://x.com/smartgaas" target="_blank" rel="noopener noreferrer" title="Twitter">
              <FaXTwitter />
            </a>
            <a href="mailto:sac@smartgaas.com" title="E-mail">
              <FaEnvelope />
            </a>
          </div>
          <div>
            <p>Central de atendimento (+55) 11-97844-4334</p>
            <p>Entre em contato conosco: sac@smartgaas.com | sac@i-fraud.com</p>
            <p>Informações para a imprensa: ceo@smartgaas.com</p>
          </div>
        </div>
        <div className='footer-right'>
          <p>Smart GaaS do Brasil Indústrias de Máquinas e Serviços LTDA</p>
          <p>CNPJ: 29.209.654/0001-01</p>
          <p>Endereço: Alameda Madeira, 53 - Alphaville Industrial e Empresarial - Barueri - SP - CEP 06454-010</p>
          <p>© SmartGaaS e © i-fraud são marcas registradas. Todos os direitos reservados.</p>
        </div>
      </div>
      <div>
        <a style={{ paddingRight: '25px' }} href="https://portal.smartgaas.com/privacy-policy">
          Política de Privacidade
        </a>
        <a href="https://portal.smartgaas.com/terms-conditions">
          Termos de Uso
        </a>
      </div>
    </footer>
  )
}
export default Footer