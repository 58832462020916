import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './header-menu.css'
import logoSmartGaas from '../../assets/logo_branco.png'
import logoIfraud from '../../assets/i-fraud-logo.png'
import { FaBars, FaTimes } from 'react-icons/fa'

const HeaderMenu = (): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('Conectando tecnologia e segurança ao seu dia a dia')
  const [img, setImg] = useState(logoSmartGaas)
  const { pathname } = useLocation()

  const toggleMenu = (): void => setIsOpen(!isOpen)

  useEffect(() => {
    setTitle(pathname === '/i-fraud'
      ? 'Plataforma i-fraud'
      : 'Conectando tecnologia e segurança ao seu dia a dia'
    )
    setImg(pathname === '/i-fraud'
      ? logoIfraud
      : logoSmartGaas
    )
  }, [pathname])

  return (
    <header className='header-menu'>
      <div className='header-container'>
        <div>
          <Link to='https://portal.smartgaas.com' title='SmartGaaS' >
            <img src={img} alt='Logo' className='header-image' />
          </Link>
        </div>
        <div>
          <h1>{title}</h1>
        </div>
        <div>
          <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/contato'>Contato</Link></li>
            </ul>
          </nav>
          <div className='hamburger' onClick={toggleMenu}>
            {isOpen ? <FaTimes color='#EFEFEF' /> : <FaBars color='#EFEFEF' />}
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderMenu